<!-- 这个相当于是那个任务box在这里 -->
<template>
        
  <div class="add_task_area">

            
    <div class="titlezone">
    <a>增加项目<span class="projectnameinaddzone">{{ project.Name }}</span>的任务</a>
    <closebutton @click="handleClose" />
    </div>
    <div class="input_task_area">
      <div class="task_container">
        <!-- 输入任务内容 -->
        <input
          class="input_text"
          v-model="taskcontent"
          type="text"
          placeholder="请输入你的任务内容"
        />
      </div>

      <div class="task_property_area">
        <div class="calendar_project_area">
          <div id="goal_list1" class="goal_list"></div>
        </div>

        <!-- 任务标签选择 -->
        <div id="tags_review_area1" class="tags_review_area" @click="clicktag">
          <div id="tasktags_area1">
            <button id="tags_button1" class="tags_button" @click="clicktag">
              <svg
                t="1602774165074"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2453"
                width="15"
                height="15"
              >
                <path
                  d="M344.064 194.56c-72.704 0-132.096 59.392-132.096 132.096s59.392 132.096 132.096 132.096c72.704 0 132.096-59.392 132.096-132.096S416.768 194.56 344.064 194.56z m0 185.344c-29.696 0-53.248-23.552-53.248-53.248 0-29.696 23.552-53.248 53.248-53.248 29.696 0 53.248 23.552 53.248 53.248 0 29.696-24.576 53.248-53.248 53.248z m656.384 143.36L512 32.768C496.64 17.408 476.16 9.216 454.656 9.216H79.872C35.84 9.216 0 45.056 0 89.088v345.088c0 21.504 8.192 40.96 23.552 56.32l503.808 501.76c15.36 15.36 35.84 23.552 57.344 23.552 21.504 0 41.984-8.192 56.32-23.552L1000.448 634.88c31.744-30.72 31.744-80.896 0-111.616zM583.68 934.912l-503.808-501.76V89.088h374.784l488.448 490.496L583.68 934.912z"
                  p-id="2454"
                  fill="#d81e06"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- 添加任务按钮 -->
      <div class="add_task_button_area">
        <button class="addtask" @click="addtaskinproject(goal.Goalcode, project.Name, project)">
          添加任务
        </button>
        <div v-show="showtagselect" class="add_tasktags_area">
          <a-select
            v-model:value="value"
            mode="multiple"
            style="width: 100%"
            placeholder="选择任务属性..."
            :max-tag-count="maxTagCount"
            :options="tasktag_options"
            @change="handleChange"
          ></a-select>
        </div>
      </div>

      <!-- 智能指数组件 -->
      <SmartIndex
        :difficultyValue="difficultyValue"
        :moodValue="moodValue"
        @update:difficultyValue="updateDifficultyValue"
        @update:moodValue="updateMoodValue"
       
      />
    </div>
  </div>
</template>



<script>

import configure_options from '../../configure'
import { defineComponent,ref } from 'vue';
import emitter from "../../eventbus.js"; 
import SmartIndex from '../smartindex.vue'; 
import { computed } from 'vue';
import { useStore } from 'vuex';
import closebutton from '../../svg/close.vue'
const axios = require('axios').default;
export default defineComponent( {
  name: 'taskbox',
  props: {
    msg: String,
    goal: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },

  mounted(){
  // this.options =this.gettasktags()
  // this.options =this.getreviewalgo()
  this.tasktag_options =this.gettasktags()
},
components:{
  SmartIndex,closebutton
},
methods:{
  onPanelChange(value, mode) {
      console.log(value, mode);
      
    },
    handleClose() {
     
      this.$emit("close"); // 向父组件发送 "close" 事件
    },
},
setup(props,context) {
  const value1 = ref(500);
    const handleChange = value => {
      console.log(`selected ${value}`);
      context.emit("testchild")
    };
    const store = useStore();
  const isVipUser = computed(() => {
  return store.getters.userLevel === 'vip';
});
    const difficultyValue = ref(0);  // 初始化难度值
    const moodValue = ref(0);  // 初始化情绪指数
    function updateDifficultyValue(newVal) {
  difficultyValue.value = newVal;
}

// 当子组件更新情绪指数时触发的函数
function updateMoodValue(newVal) {
  moodValue.value = newVal;
}
   var  options =  [{
                  id:0,
                  value:"容易的",  
                },{
                  id:1,
                  value:"麻烦事"
                },{
                  id:2,
                  value:"体力活"
                },
                {
                  id:3,
                  value:"3 minutes"
                },
                {
                  id:4,
                  value:"10 minutes"
                },
                {
                  id:5,
                  value:"重要的"
                },
                {
                  id:6,
                  value:"不舒服的"
                },
                
                
                ]

    function clicktag(){
      // alert("检测是否被调用")
    this.showtagselect = true;
  }





function gettasktags(){
    //尝试从服务器获取对应的部分 
    var final_options = []

var env =  configure_options["env"]
           
  console.log(configure_options["app_setting"][env])
           var serverhost =configure_options["app_setting"][env]["serverhost"]
    var _this = this
       axios.get(serverhost+"/v1/gettags").then(
        response=>{
          var data = response.data
              console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
         console.log(data);
         _this.items = data.tags
         // console.log(status);
         // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        
         var taglocaldata =['重要的', '害怕的', '原则', '原子', '需要深度搜索', 
         '困难的', '容易的', '急迫的', '挑战的', '不舒服的', '麻烦事', 
         '关键问题', '10分钟', '30分钟', '3分钟', '人生伟大时刻']
     for(var i=0;i<taglocaldata.length;i++){
        final_options.push({id:i,value:taglocaldata[i]})
      }

      for(var j=0;j<data.tags.length;j++){
        final_options.push({id:j,value:data.tags[j].Name})
      }
      _this.tasktag_options =final_options

      return final_options

   
        }
      ).catch(error=>{console.log(error)})
//  []

  }



  function  addtaskinproject(goalcode,projectName,project){
    // alert(`父亲任务是${parenttask.task}`)
    // alert(this.$store.state.number)
    // alert(this.taskcontent)
    // alert(this.plantime)
    if(this.taskcontent=="unspecified"||this.taskcontent==""){
      alert("必须指定任务内容")
      return
    }


    console.log("project is below")
    console.log(project)
    // alert(goalcode)
    // alert(project)
    // alert(this.taskcontent)
    console.log(goalcode,projectName)
    this.showtagselect = false;
   
   console.log("🧃🧃🧃🧃")
   console.log(moodValue,difficultyValue)
   //促进一个状态的改变
    // var task =  parenttask
    // const  scheduleids = [task.ID]
      var tagscovert = {
      "importanttag": "重要的",
      'feartag': "害怕的",
      'principletag': "原则",
      'atomtag': "原子",
      'dfstag': "需要深度搜索",
      'hardtag': "困难的",
      'easytag': "容易的",
      'urgenttag': "急迫的",
      'challengetag': "挑战的",
      'uncomfortabletag': "不舒服的",
      'troublesometag': "麻烦事",
      'keyproblemtag': "关键问题",
      'tenminutestasktag': "10分钟",
      'thirtyminutestasktag': "30分钟",
      'threeminutestasktag': "3分钟",
      'lifetag': "人生伟大时刻",
      // 'recordtag': "创造新的纪录",
    }
   

  var tasktags_dict = {
      'importanttag': 'no',
      'feartag': 'no',
      'principletag': 'no',
      'atomtag': 'no',
      'dfstag': 'no',
      'hardtag': 'no',
      'easytag': 'no',
      'urgenttag': 'no',
      'challengetag': 'no',
      'uncomfortabletag': 'no',
      'troublesometag': 'no',
      'keyproblemtag': 'no',
      'tenminutestasktag': 'no',
      'thirtyminutestasktag': 'no',
      'threeminutestasktag': 'no',
      'lifetag': 'no',
      'recordtag':'no'
    }


   var task = {
          'starttime': 'unspecified',
          'deadline': 'unspecified',
          'endtime': 'unspecified',
          'ifdissect': 'no',
          'note': 'unspecified',
          'parentid': "unspecified",
          'timedevotedto_a_task': "unspecified",
          'goal': 'unspecified',
          'parentproject': 'unspecified',
          'inboxlist': 'empty',
          "client":"gtdcli",
          "goalcode":goalcode,
          'inbox': this.taskcontent,
          "moodlevel":moodValue.value,
          "difficultylevel":difficultyValue.value,
          'finishtime': 'unspecified',
          'reviewalgolight': 'no',
          'reviewalgo': "unspecified",
          'place': 'unspecified',
          'input-time': 'Fri Aug 21 22:38:23 2020',
          'project': projectName,
          'projectcode':project.Projectcode,
          'taskstatus': 'unfinished',
          'plantime': 'unspecified',
          'taglight': 'yes',
          'tasktags': tasktags_dict,
          // 'client':"dreamforlifeweb",
          'tasktagsorigin': 'e'
        };

  // 采用循环来提取对应的tag部分
  var task_tags = []
  for(var tag in tagscovert){
    if(this.value.includes(tagscovert[tag])){
      // task_tags.push(tag)
      tasktags_dict[tag]="yes"
    }
  }



   // 直接进行循环测试
   for(var selectedtag in this.value){
    
    tasktags_dict[this.value[selectedtag]]="yes"
  }
  console.log("选中的任务属性在这里")
  console.log(task_tags)

   console.log(`观测这里的project ${projectName}，projectcode${'sss'}`)

     var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

  //  const postbody = {"parentid":parenttask.ID,"inbox":"测试任务"}
   axios.post(serverhost + '/v1/createtask', task)
    .then(response => {
      
        console.log(response.data)
        var task = response.data.task
        var tags =   JSON.parse(task.tasktags)
        var tagsfromfilter =   Object.keys(tags).filter((key) => tags[key] === "yes");
        tagsfromfilter.push("tasktext")
        task.tagsarray = tagsfromfilter
        //可以将状态传入到父亲组件
         document.getElementById("addtaskinprojectzone").style.display="none"
       document.getElementById("goaltreedatapart").style.opacity=1
       document.getElementById("goaltreedatapart").style.pointerEvents="auto"
       document.getElementById("top").style.opacity=1
      emitter.emit("createtaskINproject",task)
    })
    .catch(error=>{console.log(error)})
      return 
  }


    return {
      isVipUser,
      moodValue,
      difficultyValue,
      updateDifficultyValue,
      updateMoodValue,
      showtagselect:ref(false),
      handleChange,
      addtaskinproject,
      clicktag,
      gettasktags,
      tasktag_options:ref([]),
      value1,
      options,
      value: ref([]),
      plantime:ref(null),
      // editcontent:props.parenttask.task,
      taskcontent:ref(""),
      maxTagCount:ref(2),
      taskchecked: ref(false),
    };
  },




})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input_task_area{
  min-width: 30vw;
}
input{
  pointer-events: auto; 
}
.projectnameinaddzone{
  color:red;
}
/* 容器整体样式 */
.add_task_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: #f7f9fc;
  min-width: 33vw;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0.5rem auto;
}

/* 输入框样式 */
.input_text {
  width: 100%;
  padding: 0.5rem 0.5rem;
  font-size: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  outline: none;
}

.input_text:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 5px rgba(26, 115, 232, 0.3);
}

/* 按钮样式 */
.addtask {
  padding: 0.3rem 0.3rem;
  font-size: 0.8rem;
  color: white;
  background: #1a73e8;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.addtask:hover {
  background: #005ecb;
  transform: scale(1.05);
}

/* 标签选择区域 */
.add_tasktags_area {
  margin-top: 0.5rem;
}

.tags_button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.tags_button:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* 属性区域 */
.task_property_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

/* 智能指数组件样式 */
.smartindex {
  margin-top: 1rem;
}

.titlezone{
 display: flex;
 width: 100%;
padding-bottom: 0.1rem;
  // margin-right:10px;

  justify-content: space-between

}
</style>
