<template>
    <div id="pomodoro-app">
      <!-- Tomato Body -->
      <div class="tomato-body">
        <div class="stem"></div>
        <div class="leaf"></div>
  
        <!-- Timer Elements inside Tomato -->
        <div id="timer-elements">
          <!-- Session and Break Time Settings -->
          <div class="settings">
            <div id="session-settings">
              <div class="timer-label" id="session-label">session</div>
              <button id="session-decrement" @click="handleClick('session-decrement')">-</button>
              <span class="time-amount" id="session-length">{{ secondsToMinutes(state.sessionTime) }}</span>
              <button id="session-increment" @click="handleClick('session-increment')">+</button>
            </div>
  
            <div id="break-settings">
              <div class="timer-label" id="break-label">break</div>
              <button id="break-decrement" @click="handleClick('break-decrement')">-</button>
              <span class="time-amount" id="break-length">{{ secondsToMinutes(state.breakTime) }}</span>
              <button id="break-increment" @click="handleClick('break-increment')">+</button>
            </div>
          </div>
  
          <!-- Timer Display -->
          <div id="timer">
            <div id="time-left">{{ secondsToMMSS(state.mainTime) }}</div>
            <div id="timer-details">
              <div id="timer-label">left in your {{ state.counter }}</div>
              <div id="timer-controls">
                <button id="start_stop" @click="handleClick('start_stop')">{{ state.buttonLabel }}</button>
                <button id="reset" @click="handleClick('reset')">reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Beep Sound -->
      <audio ref="beep" type="audio/mpeg" src="https://freesound.org/data/previews/250/250629_4486188-lq.mp3"></audio>
    </div>
  </template>
  
  <script setup>
  import { ref, reactive } from 'vue';
  
  // State management
  const state = reactive({
    sessionTime: 1500, // default to 25 min (=1500 sec)
    breakTime: 300, // default to 5 min (=300 sec)
    mainTime: 1500, // default to 25 min (=1500 sec)
    counter: 'session',
    status: 'paused',
    buttonLabel: 'start',
  });
  
  const beep = ref(null);
  let timeLeft = null;
  
  // Helper Functions
  const secondsToMMSS = (timeInSeconds) => {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = timeInSeconds - minutes * 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };
  
  const secondsToMinutes = (timeInSeconds) => timeInSeconds / 60;
  
  const alarmSound = (action) => {
    if (action === 'play') {
      beep.value.play();
    } else if (action === 'reset') {
      beep.value.pause();
      beep.value.currentTime = 0;
    }
  };
  
  const countDown = () => {
    timeLeft = setInterval(() => {
      if (state.mainTime > 0) {
        state.mainTime -= 1;
      } else {
        clearInterval(timeLeft);
        alarmSound('play');
        if (state.counter === 'session') {
          state.counter = 'break';
          state.mainTime = state.breakTime;
        } else {
          state.counter = 'session';
          state.mainTime = state.sessionTime;
        }
        countDown();
      }
    }, 1000);
  };
  
  // Button Handlers
  const handleClick = (action) => {
    switch (action) {
      case 'session-increment':
        if (state.sessionTime < 3600) {
          state.sessionTime += 60;
          state.mainTime = state.sessionTime;
          state.counter = 'session';
          state.status = 'paused';
          state.buttonLabel = 'start';
        }
        clearInterval(timeLeft);
        break;
      case 'session-decrement':
        if (state.sessionTime > 60) {
          state.sessionTime -= 60;
          state.mainTime = state.sessionTime;
          state.counter = 'session';
          state.status = 'paused';
          state.buttonLabel = 'start';
        }
        clearInterval(timeLeft);
        break;
      case 'break-increment':
        if (state.breakTime < 3600) {
          state.breakTime += 60;
          state.mainTime = state.sessionTime;
          state.counter = 'session';
          state.status = 'paused';
          state.buttonLabel = 'start';
        }
        clearInterval(timeLeft);
        break;
      case 'break-decrement':
        if (state.breakTime > 60) {
          state.breakTime -= 60;
          state.mainTime = state.sessionTime;
          state.counter = 'session';
          state.status = 'paused';
          state.buttonLabel = 'start';
        }
        clearInterval(timeLeft);
        break;
      case 'start_stop':
        if (state.status === 'paused') {
          state.status = 'counting';
          state.buttonLabel = 'pause';
          countDown();
        } else {
          clearInterval(timeLeft);
          state.status = 'paused';
          state.buttonLabel = 'resume';
        }
        break;
      case 'reset':
        state.sessionTime = 1500;
        state.breakTime = 300;
        state.mainTime = 1500;
        state.counter = 'session';
        state.status = 'paused';
        state.buttonLabel = 'start';
        clearInterval(timeLeft);
        alarmSound('reset');
        break;
    }
  };
  </script>
  
  <style scoped lang="scss">
  /* Share Tech Mono font from Google for the clock display. */
//   @import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono");
  
  $background-color: #e0faff;
  $tomato-color1: tomato;
  $tomato-color2: red;
  $shadow-color: rgba(0, 0, 0, 0.8);
  $green1: #00b31b;
  $green2: #1cd939;
  $green3: #1cd939;
  
  $setting-color: rgba(255, 255, 255, 0.3);
  $button: $setting-color;
  $button-hover: rgba(255, 255, 255, 0.6);
  
  body {
    background: $background-color; /* light blue, complementary to tomato color */
    font-family: "Share Tech Mono", monospace;
  }
  
  #pomodoro-app { 
    display: flex;
    justify-content: center;
    height: 95vh;
    align-items: center;
    position: relative; /* To position timer content on top of tomato */
  }
  
  .tomato-body {
    position: relative;
    width: 340px;
    height: 300px;
    border-radius: 170px 170px 150px 150px / 160px 160px 140px 140px;
    background: $tomato-color1;
    background: radial-gradient(circle at 30% 30%, $tomato-color1, $tomato-color2);
    box-shadow: inset -5px -10px 30px -10px $shadow-color;
  }
  
  .stem {
    position: relative;
    top: -15px;
    left: 147px;
    z-index: 3;
    width: 20px;
    height: 40px;
    background: $green1;
    border-radius: 0 0 5px 5px;
    transform: rotate(-10deg);
  }
  
  .leaf { 
    position: relative;
    top: -60px;
    left: 190px;
    width: 0px;
    height: 0px;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 0px solid transparent;
    border-right: 30px solid $green1;
    transform: rotate(115deg);
    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: 60px;
      width: 0px;
      height: 0px;
      border-top: 30px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 0px solid transparent;
      border-right: 20px solid $green2;
      transform: rotate(120deg);
    }
    
    &:after{
      content: "";
      position: absolute;
      top: 40px;
      left: 25px;
      width: 0px;
      height: 0px;
      border-top: 35px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 0px solid transparent;
      border-right: 20px solid $green3;
      transform: rotate(340deg);
    }
  }
  
  #timer-elements {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  button {
    border: none;
    background: $button;
    padding: 0.09em 0.5em;
    font-family: inherit;
    transition: background 100ms ease-out;
    
    &:hover { 
      background: $button-hover;
    }
    
    &:focus { 
      outline: none;
    }
    
    &:active { 
      transform: scale(0.96, 0.96);
    }
  }
  
  .settings{
    text-align: center;
    display: flex; 
    justify-content: space-between;
  }
  
  .time-amount {
    padding: 0.1em 0;
    margin: 0 0.2em;
    background: $setting-color;
    display: inline-block; 
    width: 40px;
  }
  
  #timer {
    margin-top: 20px;
    width: 175px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;  
  }
  
  #time-left {
    font-size: 67px;
  }
  
  #timer-label {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  #timer-controls {
    display: flex;
    justify-content: space-between;
  }
  
  #timer-controls button {
    width: 70px;
  }
  
  footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: black;
    padding: 0.5em;
    text-align: center;
    font-size: 0.8em;
    color: #ccc;
  }
  
  footer a {
    text-decoration: underline;
    color: #aaa;
  }
  
  footer a:hover {
    color: #888;
  }
  </style>
  