// store.js
import { createStore } from 'vuex';
import axios from 'axios';
// import VuexPersistence from 'vuex-persistedstate';
import VuexPersist from 'vuex-persist';
const vuexPersist = new VuexPersist({
  key: 'vuex', // You can change this to your preferred storage key
  storage: localStorage, // You can also use sessionStorage
});
const store = createStore({
  state() {
    return {
      user: null,
      showClock: false, 
      isFullscreen: false,
      showgeyan:false,
      selectedFont: 'default',
      headerStyle: {},
    };
  },
  mutations: {
    toggleFullscreen(state) {
      state.isFullscreen = !state.isFullscreen;  // 切换全屏状态
    },
    setFullscreen(state, value) {
      state.isFullscreen = value;  // 设置全屏状态
    },
    toggleClock(state) {
      state.showClock = !state.showClock;
    },
    setShowClock(state, value) {
      state.showClock = value;
    },

    setUser(state, user) {
      state.user = user;
    },
    setSelectedFont(state, font) {
      state.selectedFont = font;
    },
    setHeaderStyle(state, style) {
      state.headerStyle = style;
    },
    setStoryorder(state, order) {
      state.storyorder = order;
    },
    setGoaltreechildstyle(state, style) {
      state.goaltreechildstyle = style;
    },
    TOGGLE_GEYAN(state) {
      state.showgeyan = !state.showgeyan;
    },
    SET_GEYAN(state, value) {
      state.showgeyan = value;
    },



  },
  actions: {
    toggleGeyan({ commit }) {
      commit('TOGGLE_GEYAN');
    },
    setGeyan({ commit }, value) {
      commit('SET_GEYAN', value);
    },
    toggleFullscreen({ commit }) {
      commit('toggleFullscreen');
    },
    setFullscreen({ commit }, value) {
      commit('setFullscreen', value);
    },
    toggleClock({ commit }) {
      commit('toggleClock');
    },
    setShowClock({ commit }, value) {
      commit('setShowClock', value);
    },
    async fetchUser({ commit }) {
      try {
        console.log("📌📌📌")
        const response = await axios.get('/v1/userinfo', { withCredentials: true });
        const data = response.data;
        const userLevel = data.userlevel;

        if (userLevel === 'vip') {
          commit('setHeaderStyle', { background: "#e11858" });
          // 其他处理，比如连接 WebSocket
        }

        const setting = data.setting || "{}";
        const selectedFont = (() => {
          try {
            return JSON.parse(setting).font || "default";
          } catch (e) {
            return "default";
          }
        })();
        // commit("goaltreechildstyle",setting.goaltreechildstyle)
        commit('setSelectedFont', selectedFont);
        commit('setUser', data);
        commit('setGoaltreechildstyle', setting.goaltreechildstyle);
        commit('setStoryorder', setting.storyorder);
        
      } catch (error) {
        console.error("获取用户信息失败：", error);
        commit('setSelectedFont', "default");
      }
    },
  },
  getters: {
    showgeyan: (state) => state.showgeyan,
    user(state) {
      return state.user;
    },
    userLevel(state) {
        return state.user ? state.user.userlevel : '';
      },
    selectedFont(state) {
      return state.selectedFont;
    },
    headerStyle(state) {
      return state.headerStyle;
    },
    storyorder(state) {
      return state.storyorder;
    },
    goaltreechildstyle(state) {
      return state.goaltreechildstyle;
    },
  },
  plugins: [vuexPersist.plugin],
  
});

export default store;
