<template>
    <div class="projects-container">
      <!-- 循环显示项目进度条组件 -->
       <div>项目进度推进器</div>
      <div v-for="project in serverprojectsprogress" :key="project.Projectcode">
        <!-- 当进度条 > 0 且为 VIP 用户时显示 -->
         <div class="infoblock">
         <div>{{ project.project}}</div>
  
        <progressbarUI v-if="project?.Progressbar > 0 " :project="project" />
         </div>
        </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  import axios from "axios";
  import progressbarUI from "../tools/progressbar.vue";
  import emitter from "../../eventbus.js"; 
  // 定义响应式变量来存储服务端项目数据
  const serverprojectsprogress = ref([]);
  
  // 假设 isVipUser 是一个动态变量，判断用户是否为 VIP
//   const isVipUser = ref(true); // 这里默认设置为 true，实际情况可从用户状态中获取
   emitter.on("refreshPROJECTprogressbar",msg=>{
          console.log(msg)
          fetchProjectsProgress()
       
      })
  // 从服务端获取项目数据
  const fetchProjectsProgress = async () => {
    try {
      const response = await axios.get("/v1/projectsprogressjson");
      if (response.status === 200 && response.data.projects) {
        serverprojectsprogress.value = response.data.projects;
      }
    } catch (error) {
      console.error("Error fetching project progress:", error);
    }
  };
  
  // 组件挂载时获取数据
  onMounted(() => {
    fetchProjectsProgress();
  });
  </script>
  
  <style scoped lang="scss">
  .projects-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: white;
    border: solid rgb(58, 161, 230) 2px;
    border-radius: 5%;
    
    .infoblock{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
    }
  
    /* 可根据需求自定义样式 */
  }
  </style>
  