<template>
    <div class="daily-alert" :style="alertStyle">
      <p>{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "DailyAlert",
    props: {
      message: {
        type: String,
        required: true
      },
      backgroundColor: {
        type: String,
        default: "#fef3c7" // 默认背景色浅黄色
      },
      textColor: {
        type: String,
        default: "#333" // 默认文字颜色
      },
      fontSize: {
        type: String,
        default: "16px" // 默认字体大小
      }
    },
    computed: {
      alertStyle() {
        return {
          backgroundColor: this.backgroundColor,
          color: this.textColor,
          padding: "15px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          fontSize: this.fontSize,
          textAlign: "center",
          margin: "10px auto",
          maxWidth: "80%"
        };
      }
    }
  };
  </script>
  
  <style scoped>
  .daily-alert {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  