<template>
  <div class="spiral-container">
    <div class="header">
      <span class="title">成就热力图</span>
      <a-segmented v-model:value="selectedOption" :options="segmentOptions" />
    </div>
    <svg :width="width" :height="height" class="spiral-svg">
      <!-- 螺旋路径 -->
      <path :d="spiralPath" stroke="lightgray" fill="none" />

      <!-- 数据点 -->
      <g v-for="(day, index) in data" :key="index">
        <circle
          v-for="(badge, type) in day.badges"
          :key="type"
          :cx="day.cx + badgeOffsets[badge.type || 'default']"
          :cy="day.cy"
          :r="badge.count > 0 ? badge.count * 2 + 4 : 0"
          :fill="badgeColors[badge.type || 'default']"
          @mouseover="showTooltip(day, $event)"
          @mouseleave="hideTooltip"
          @click="onDayClick(day)"
        />
      </g>
    </svg>

    <div  class="tooltip">
  <div>
    <svg v-if="tooltip.day?.date" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5v1h8V.5a.5.5 0 0 1 1 0v1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2h1v-1a.5.5 0 0 1 .5-.5zM2 4v10.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5V4H2z"/>
      <text x="3" y="14" font-size="10">T</text>
    </svg>
    {{ tooltip.day?.date }}
  </div>
  <div class="badge-container">
    <dynamicalicon
  v-for="badge in filteredBadges"
  :key="badge.type"
  :type="badge.type"
  :color="badgeColors[badge.type || 'default']"
  size="20"
/>

  </div>
</div>

    <!-- Tooltip -->
    <div v-if="false" class="tooltip">
      <div><strong>Date:</strong> {{ tooltip.day?.date || "N/A" }}</div>
      <div v-for="badge in tooltip.day?.badges || []" :key="badge.type">
        <strong>{{ badge.type }}:</strong> {{ badge.count }}
      </div>
    </div>
  </div>
</template>

<script>
import { Segmented } from "ant-design-vue";
import axios from "axios";
import dynamicalicon from "../tools/dynamicalicon.vue"
export default {
  components: {
    "a-segmented": Segmented,
    dynamicalicon,
  },
  data() {
    return {
      width: 600, // SVG 宽度
      height: 600, // SVG 高度
      turns: 6, // 螺旋圈数
      data: [], // 从服务端解析后的数据
      tooltip: {
        visible: false,
        day: null,
        x: 0,
        y: 0,
      },
      badgeColors: {
        gold: "#FFD700",
        silver: "#C0C0C0",
        bronze: "#CD7F32",
        hard: "	#FF4500",
        lowmood: "#CD7F32",
        novelty:"red",
        mutiplegoal: "#8A2BE2",
        hardrelated:"#4169E9",
        default: "#00FF00", // 默认绿色
      },
      badgeOffsets: {
        gold: 0,
        silver: -10,
        bronze: 10,
        hard: -10,
        hardrelated:-20,
        lowmoodrelated:20,
        mutiplegoal:0,
        novelty:25,
        lowmood: 10,
        default: 0, // 默认徽章没有偏移
      },
      segmentOptions: [
        { label: "默认", value: "default" },
        { label: "按月", value: "month" },
        { label: "按年", value: "year" },
      ],
      selectedOption: "default", // Segmented 当前选中值
    };
  },
  computed: {
    filteredBadges() {
    return (this.tooltip.day?.badges || []).filter((badge) => badge.count > 0);
  },
    spiralPath() {
      const path = [];
      const centerX = this.width / 2;
      const centerY = this.height / 2;
      const maxRadius = Math.min(centerX, centerY) - 20;
      const totalPoints = this.data.length;

      const startRadius = 50;
      const radiusIncrement = (maxRadius - startRadius) / totalPoints;

      for (let i = 0; i < totalPoints; i++) {
        const angle = (i / totalPoints) * (this.turns * 2 * Math.PI);
        const radius = startRadius + i * radiusIncrement;

        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        path.push(`${i === 0 ? "M" : "L"}${x},${y}`);
      }
      return path.join(" ");
    },
  },
  watch: {
    selectedOption(newValue) {
      this.fetchData(newValue);
    },
  },
  methods: {
    fetchData(datetype) {
      axios
        .get("/v1/nbdatabadages", {
          params: { type: datetype },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.initializeData(response.data.data);
          } else {
            console.error("Invalid response structure:", response.data);
            this.data = [];
          }
        })
        .catch((error) => {
          console.error("Failed to fetch badge data:", error);
        });
    },
    initializeData(serverData) {
      const centerX = this.width / 2;
      const centerY = this.height / 2;
      const maxRadius = Math.min(centerX, centerY) - 20;

      const totalPoints = serverData.length;
      const startRadius = 50;
      const radiusIncrement = (maxRadius - startRadius) / totalPoints;

      this.data = serverData.map((item, i) => {
        const angle = (i / totalPoints) * (this.turns * 2 * Math.PI);
        const radius = startRadius + i * radiusIncrement;

        const cx = centerX + radius * Math.cos(angle);
        const cy = centerY + radius * Math.sin(angle);

        const badges =
  item.badges && item.badges.length > 0 && !item.badges.every(badge => badge.count === 0)
    ? item.badges
    : [{ type: "default", count: 1 }];
        return {
          date: item.date,
          badges,
          cx,
          cy,
        };
      });
      if (this.data.length > 0) {
    const lastDay = serverData[serverData.length - 1];
    this.tooltip.day = lastDay;
    this.tooltip.x = lastDay.cx;
    this.tooltip.y = lastDay.cy;
  }
    },
    showTooltip(day, event) {
      this.tooltip.visible = true;
      this.tooltip.day = day;
      this.tooltip.x = event.pageX + 10;
      this.tooltip.y = event.pageY + 10;
    },
    hideTooltip() {
      this.tooltip.visible = false;
    },
    onDayClick(day) {
      console.log("Day clicked:", day);
    },
  },
  mounted() {
    this.fetchData(this.selectedOption);
  },
};
</script>

<style scoped>
.spiral-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  background: rgb(247, 246, 242);
}

.spiral-svg {
  display: block;
  margin: auto;
}

circle {
  cursor: pointer;
  transition: r 0.2s ease, fill 0.2s ease;
}

circle:hover {
  stroke: black;
  stroke-width: 2px;
}

.tooltip {
  display: flex;
  flex-direction: column;
  background: rgba(86, 4, 119, 0.8);
  color: white;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none;
  font-size: 12px;
}

.tooltip div {
  margin-bottom: 4px;
  text-align: left;
}

.header {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: space-between; /* 左右对齐 */
  padding: 10px; /* 根据需要调整间距 */
  background-color: #f9f9f9; /* 可选：设置背景色 */
}
.title{
  font-family: "goodfont";
}

.header span {
  margin-left: 10px; /* 可选：控制与左侧边距的距离 */
  font-size: 16px; /* 可选：调整文字大小 */

}

.header a-segmented {
  margin-right: 10px; /* 可选：控制与右侧边距的距离 */
}



.tooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.badge-container {
  display: flex;
  gap: 8px;
}

.badge-icon {
  display: inline-block;
  vertical-align: middle;
}

</style>
