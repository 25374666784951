<template>
    <div class="chat-container">
      <div class="messages">
        <div 
          v-for="(message, index) in messages" 
          :key="index" 
          :class="['message', message.sender === 'user' ? 'user-message' : 'server-message']"
          ref="message" 
        >
          <template v-if="message.type === 'text'">
            <span class="text">{{ message.text }}</span>
          </template>
          <template v-if="message.type === 'table'">
            <dynamicaltable :headers="message.Headers" :rows="message.Rows" />
          </template>
          <template v-if="message.type === 'tasks'">
            <Taskrecursiontree
              v-show="showpridetasks" 
              @didi="test" 
              :tasktype="message.tasktypedata" 
              :showtoolbox="message.showtoolboxdata" 
              :showcheckbox="message.showcheckboxdata" 
              :model="message.tasks"
            />
          </template>
          <template v-if="message.type === 'image'">
            <img :src="message.url" alt="image" class="image-message" />
          </template>
        </div>
      </div>
      <div class="input-container">
        <input 
          v-model="newMessage" 
          @keyup.enter="sendMessage" 
          type="text" 
          placeholder="Type your message here..." 
          class="message-input"
        />
        <button @click="sendMessage" class="send-button">
    <div v-if="isLoading" class="loader"></div> <!-- 显示加载动画 -->
    <span v-else>✈️</span>  <!-- 显示发送按钮图标 -->
  </button>
        
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import configure_options from '../configure';
  import Taskrecursiontree from "./taskrecursiontree";
  import dynamicaltable from "./dynamicaltable";
  import emitter from "../eventbus.js"; 
  export default {
    data() {
      return {
        messages: [],
        newMessage: '',
        isLoading:false,
        user: 'User',
        showpridetasks: true, 
        tasktypedata: 'defaultTaskType',
        showtoolboxdata: true,
        showcheckboxdata: true,
        treeData: {},
        ws: null // WebSocket 连接对象
      };
    },
    components: {
      Taskrecursiontree,
      dynamicaltable,
    },
    mounted() {
      this.connectWebSocket(); // 组件挂载时连接 WebSocket
    },
    beforeUnmount() {
      if (this.ws) {
        this.ws.close(); // 组件销毁时关闭 WebSocket 连接
      }
    },
    methods: {
        connectWebSocket() {
            var env = configure_options["env"]
            var wsUrl = "ws://127.0.0.1:8081/v1/ws"; // WebSocket 服务器地址
     if(env=="devnginx"){
       console.log("dev mode")
     }else{
     wsUrl = "wss://www.cosmosatom.cc/v1/ws"
     }


  this.ws = new WebSocket(wsUrl);

  this.ws.onopen = () => {
    console.log("WebSocket connection opened.");
  };

  this.ws.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.log("Received via WebSocket:", message);

    var messagetype = message.type;
    var typegroup = message.typegroup || [];
    var datagroup = message.datagroup;

    // 遍历 typegroup 并处理每种类型的数据
    if (messagetype === 'mixed' && datagroup && Array.isArray(typegroup)) {
      typegroup.forEach(type => {
        if (type === 'text' && datagroup.text) {
          var textData = datagroup.text.message;
          this.messages.push({ sender: 'server', text: textData, type: 'text' });
        } else if (type === 'todo' && datagroup.todo) {
          var tasks = datagroup.todo.tasks;
          var tasktype = datagroup.todo.tasktype;
          var tasktypedata = "pridetask";
          var showtoolboxdata = false;
          var showcheckboxdata = false;

          if (tasktype == "todo") {
            tasktypedata = "todo";
            showtoolboxdata = true;
            showcheckboxdata = true;
          }

          var treedata = this.builtreedata(tasks);
          this.messages.push({
            sender: 'server',
            tasks: treedata,
            tasktypedata: tasktypedata,
            showcheckboxdata: showcheckboxdata,
            showtoolboxdata: showtoolboxdata,
            type: 'tasks'
          });
        }
      });
    } else {
      // 处理非混合类型消息
      var keyword = message.keyword;
      var data = message[keyword];

      if (messagetype === 'text') {
        this.messages.push({ sender: 'server', text: data, type: 'text' });
      } else if (messagetype === 'tasks') {
        // 同样处理 tasks 类型
        var tasktype = message["tasktype"];
        var tasktypedata = "pridetask";
        var showtoolboxdata = false;
        var showcheckboxdata = false;
        if (tasktype == "todo") {
          tasktypedata = "todo";
          showtoolboxdata = true;
          showcheckboxdata = true;
        }
        var treedata = this.builtreedata(data);
        this.messages.push({
          sender: 'server',
          tasks: treedata,
          tasktypedata: tasktypedata,
          showcheckboxdata: showcheckboxdata,
          showtoolboxdata: showtoolboxdata,
          type: 'tasks'
        });
      } else if (messagetype === 'image') {
        this.messages.push({ sender: 'server', url: data, type: 'image' });
      } else if (messagetype === 'table') {
        const headers = data.headers;
        const rows = data.rows;
        this.messages.push({ sender: 'server', Headers: headers, Rows: rows, type: 'table' });
      }
    }

    this.$nextTick(() => {
      this.scrollToLatestMessage();
    });
  };

  this.ws.onerror = (error) => {
    console.log("WebSocket error:", error);
  };

  this.ws.onclose = () => {
    console.log("WebSocket connection closed.");
  };
}
,
      async sendMessage() {
        this.isLoading = true; 
        if (this.newMessage.trim() !== '') {
          // 用户消息
          this.messages.push({ sender: 'user', text: this.newMessage, type: 'text' });
         
    
          var env = configure_options["env"];
          var serverhost = configure_options["app_setting"][env]["serverhost"];
          if(this.newMessage.includes("演示气球")){
            emitter.emit("showbaloon")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "气球已经展示", type: 'text' });
            return
          }
          
          if(this.newMessage.includes("关闭气球")){
            emitter.emit("offbaloon")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "气球展示关闭", type: 'text' });
            return
          }

          if(this.newMessage.includes("展示热力图")){
            emitter.emit("showheatmap")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "难度指数热力图已经展示", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭热力图")){
            emitter.emit("offheatmap")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "热力图展示关闭", type: 'text' });
            return
          }
          if(this.newMessage.includes("展示精力分配图")){
            emitter.emit("showtreemap")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "精力分配图已经展示", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭精力分配图")){
            emitter.emit("offtreemap")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "精力分配图展示关闭", type: 'text' });
            return
          }
          if(this.newMessage.includes("展示推进器")){
            emitter.emit("showprojectsprogress")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "进度推进器已经展示", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭推进器")){
            emitter.emit("offprojectsprogress")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "进度推进器展示关闭", type: 'text' });
            return
          }
          if(this.newMessage.includes("展示格言")){
            emitter.emit("showgeyan")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "进度推进器已经展示", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭格言")){
            emitter.emit("offgeyan")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "进度推进器展示关闭", type: 'text' });
            return
          }


          if(this.newMessage.includes("展示番茄")){
            emitter.emit("showpomodoro")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "番茄时钟已经展示", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭番茄")){
            emitter.emit("offpomodoro")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "番茄时钟展示关闭", type: 'text' });
            return
          }


          if(this.newMessage.includes("展示时钟")){
            emitter.emit("showclock")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "时钟已经展示", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭时钟")){
            emitter.emit("offclock")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "时钟已经关闭", type: 'text' });
            return
          }

          if(this.newMessage.includes("展示甜甜圈")){
            emitter.emit("showdonut")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "donut展示开始", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭甜甜圈")){
            emitter.emit("offdonut")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "donut展示结束", type: 'text' });
            return
          }



          if(this.newMessage.includes("展示舞蹈")){
            emitter.emit("showdance")
            this.isLoading = false; 
            this.messages.push({ sender: 'server', text: "舞蹈展示开始", type: 'text' });
            return
          }
          if(this.newMessage.includes("关闭舞蹈")){
            this.isLoading = false; 
            emitter.emit("offdance")
            this.messages.push({ sender: 'server', text: "舞蹈展示结束", type: 'text' });
            return
          }


          try {
            
            const response = await axios.post(serverhost + '/v1/chatwithatom', {
              message: this.newMessage
            });
    
            // 处理后端返回的消息
            var messagetype = response.data.type;
            var keyword = response.data.keyword;
            var data = response.data[keyword];
    
            if (messagetype === 'text') {
              this.messages.push({ sender: 'server', text: data, type: 'text' });
            }
            
           else if(messagetype=='action'){
            var actionname = data["action"]
            // alert(actionname)
            if(actionname=="countdowntimer"){
                
                var totaltime = data["totaltime"]
                var timerui = data["timerui"]
               
                // alert(`server timer total is ${totaltime}`)
                emitter.emit("showcountdowntimer",{"tt":totaltime,"ui":timerui})
                this.messages.push({ sender: 'server', text: "倒计时已经显示", type: 'text' });
            }
       
            if(actionname=="notification"){
                
           
                emitter.emit("shownotification",20)
                this.messages.push({ sender: 'server', text: "notification已经显示", type: 'text' });
            }
           }            
            else if (messagetype === 'tasks') {
              var tasktype = response.data["tasktype"];
              var tasktypedata = "pridetask";
              var showtoolboxdata = false;
              var showcheckboxdata = false;
              if(tasktype == "todo"){
                tasktypedata = "todo";
                showtoolboxdata = true;
                showcheckboxdata = true;
              } else {
                console.log("default to pride");
              }
              var treedata =  this.builtreedata(data);
              this.messages.push({ sender: 'server', tasks: treedata, tasktypedata: tasktypedata, showcheckboxdata: showcheckboxdata, showtoolboxdata: showtoolboxdata, type: 'tasks' });
            } else if (messagetype === 'image') {
              this.messages.push({ sender: 'server', url: data, type: 'image' });
            } else if (messagetype === 'table') {
              const headers = data.headers;
              const rows = data.rows;
              this.messages.push({ sender: 'server', Headers: headers, Rows: rows, type: 'table' });
            }
          } catch (error) {
            console.error('Error sending message to backend:', error);
            this.messages.push({ sender: 'server', text: "当前服务异常，请稍后再试", type: 'text' });
          }
          this.isLoading = false; 
          this.newMessage = '';
          this.$nextTick(() => {
            this.scrollToLatestMessage();
          });
        }
      },
      scrollToLatestMessage() {
        const lastMessage = this.$refs.message[this.messages.length - 1];
        if (lastMessage) {
          lastMessage.scrollIntoView({ behavior: 'smooth' });
        }
      },
      builtreedata(tasks) {
        this.showpridetab = false;
        this.showloading = true;
        this.tasktypedata = "todaytask";
        this.showtoolboxdata = true;
        this.showcheckboxdata = true;
        this.todaytabstatus = "tabactive";
        this.tomorrowtabstatus = "no";
        this.pridetabstatus = "no";
        this.showpridetasks = true;
    
        var searchedtasks = tasks;
        console.log("--------------");
        var alltasksid = [];
        var allparentid = [];
        for(var i = 0; i < searchedtasks.length; i++){
          searchedtasks[i].children = [];
          alltasksid.push(searchedtasks[i].ID.toString());
          allparentid.push(searchedtasks[i].parentid);
        }
        console.log("++++++++++++++++");
        console.log(searchedtasks);
    
        function buildTree(array) {
          let tree = [];
          for (let i = 0; i < array.length; i++) {
            console.log(i);
            console.log(alltasksid.includes(array[i].parentid));
            if(array[i].parentid == "unspecified" || alltasksid.includes(array[i].parentid) == false){
              console.log("应该在第一层");
              tree.push(array[i]);
            } else {
              let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop();
              console.log(array[i].parentid);
              console.log(parent);
              parent.children.push(array[i]);
            }
          }
          return tree;
        }
    
        const tasktree = buildTree(searchedtasks);
    
        var treeData = {
          name: "这是今天的任务系列,注意这里的数据",
          root: true,
          children: tasktree
        };
        this.showloading = false;
        console.log("final return");
        console.log(tasktree);
    
        return treeData;
      },
      test(data) {
        console.log("Taskrecursiontree event received:", data);
      }
    }
  };
  </script>
  
  <style scoped>

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  .chat-container {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    max-height: 95vh;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  .message {
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 4px;
    max-width: 90%;
    word-wrap: break-word;
  }
  .user-message {
    background-color: #b1eec5;
    align-self: flex-start;
  }
  .server-message {
    background-color: #edeff5;
    align-self: flex-end;
  }
  .input-container {
    display: flex;
  }
  .message-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .send-button {
    margin-left: 8px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  .send-button:hover {
    background-color: #0056b3;
  }
  .image-message {
    max-width: 100%;
    border-radius: 4px;
  }
  </style>
  