<template>
  <div id="userpage">
    <div id="top">
      <nav class="topnav">
        <div class="logotitle">
          <!-- doledole.life -->
       
          <img
          class="imageforlogin"
          style="width:50px;height:50px;margin-left: 50px;"
          src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/orangenew.png"
      
    
        />
        <h1 class="sci-fi-text topsci" >
           
          <span class="animated-text">at</span>
            <img  ref="rocketIcon" src="../assets/rocketpro.gif" 
            alt="Rocket GIF" class="rocket-icon"
            :style="{ animationDuration: animationDuration }"
            @mouseenter="speedUp" @mouseleave="restoreSpeed">
            <span class="animated-text">mTask</span>
            
          </h1>
    
        </div>
        
     
        <div class="topbuttonzone">
  <button class="pushable" v-show="true">
    <span class="front"><a href="/docs" style="color: white;" target="_blank">{{ $t('doctext') }}</a></span>
  </button>
  <button class="pushable" @click="showfeaturesfunc">
    <span class="front">{{ $t('featuretext') }}</span>
  </button>
  <button class="pushable" @click="showusersystemfunc">
    <span class="front logincaption">{{ $t('logintext') }}</span>
  </button>
</div>

      </nav>
    </div>

    <div id="middlezone" v-show="showusersystem" class="clear_fix">
      <div id="showimage" v-if="showimage">
        <img
          class="imageforlogin"
          src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/xjxdrlnight.jpg"
        
          title="night"
        />
        <div class="imageinfo">夜色.2016年</div>
      </div>

      <!-- 用户协议区间 -->
      <div class="agreezone"  v-show="showagreement" >
      <button  id="agree"  @click="showimagezone">x</button>
      <agreezone />
    </div>
      <div id="usersystem" v-if="showusersystem">
        <div class="container">
          <div class="info">
            <h1>积水成渊，蛟龙生焉</h1>
          </div>
        </div>
        <div class="userform form">
          <div class="thumbnail">
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/hat.svg"
            />
          </div>
          <!-- i use the attibute to not open a new tab https://www.thesitewizard.com/html-tutorial/open-links-in-new-window-or-tab.shtml-->
          <form v-if="!showlogin && showregister" class="login-form registerform" enctype="application/json">
            <input
              type="text"
              name="username"
              placeholder="输入你喜欢的昵称"
              v-model="registername"
            />
            <input
              type="text"
              name="email"
              placeholder="输入你的邮件地址"
              v-model="registeremail"
            />
            <div class="emailverifyzone"> <input
              type="text"
              name="verify code"
              placeholder="输入邮箱收到的验证码"
              v-model="verifycode"
            />
            
            <!-- <div class="verifybutton" @click="getemailverifycode">点击获取验邮箱验证码</div> -->
          
            
            <a-button type="primary" @click="getemailverifycode" :disabled = "buttondisable" > {{ emailcodeword}}</a-button>
          </div>
            
            <input
              type="password"
              name="password"
              placeholder="输入您的密码"
              v-model="registerpassword"
            />
            <input
              type="password"
              name="repeatpassword"
              placeholder="重新输入密码"
              v-model="repeatpassword"
            />
            <a-checkbox v-model:checked="checked"></a-checkbox><a @click="showagreezone">同意软件使用条款</a>
            <!-- <p type="button"  id="create_button" class="p_button">create</p> -->
        
            
           
            <button
              type="button"
              id="login_button"
              class="p_button"
              @click="toregister()"
            >
            {{ $t('create') }}
            </button>
            <p class="message">
              {{ $t('hadaccount') }} ?
              <a osrc="#" @click="showlogin = true;showregister=false;showreset=false"> {{ $t('gologin') }}</a>
            </p>
          </form>
          <form v-if="showlogin" class="login-form">
            <input
              type="text"
              name="email"
              placeholder="请输入你的邮件地址"
              v-model="loginemail"
            />
            <input
              type="password"
              name="password"
              placeholder= "请输入密码"
              v-model="loginpassword"
            />

            <!--input hidden-->
            <input
              type="hidden"
              name="client"
              value="web"
              placeholder="client"
            />
            <!-- <p id="login_button" class="p_button">login</p> -->
            <button
              type="button"
              id="login_button"
              class="p_button"
              @click="tologin()"
            >
       
            {{ isLoggingIn ? $t('loggingIn') : $t('logintext') }}
            </button>

            <p class="message">
              {{ $t('noregister') }} ?
              <a osrc="#" @click="showlogin = false;showregister=true;showreset=false">{{ $t('createacc') }}  </a>
            </p>
            <p class="message">
              {{ $t('forgetpass') }} ?
              <a osrc="#" @click="showresetfunc">{{ $t('resetpass') }}</a>
            </p>
          </form>

          <form v-if="!showlogin&&showreset" class="reset-form" enctype="application/json">
         
            <input
              type="text"
              name="email"
              placeholder="请输入邮件地址"
              v-model="resetemail"
            />
            <div class="resetverifyzone"><input
              type="resetverifycode"
              name="resetverifycode"
              placeholder="请输入邮件验证码"
              v-model="resetverifycode"
            />
            <!-- 这种方式可能存在一定风险？ -->
            <a-button type="primary" @click="getResetemailrverifycode" :disabled = "resetbuttondisable" > {{ resetemailcodeword}}</a-button>
            </div>
            <input
              type="password"
              name="password"
              placeholder="请输入新密码"
              v-model="resetpassword"
            />
            <input
              type="password"
              name="repeatpassword"
              placeholder="重复新密码"
              v-model="repeatresetpassword"
            />
         
       
           
            <button
              type="button"
              id="login_button"
              class="p_button"
              @click="toreset()"
            >
            {{ $t('reset') }}
            </button>
            <p class="message">
              {{ $t('knowpass') }}?
              <a osrc="#" @click="showlogin = true">{{ $t('gologin') }}</a>
            </p>
          </form>
        </div>
      </div>
    </div>

    <div v-show="!showusersystem">
      <!-- <main class="relative shell_primaryContent__Aoh08" id="primary-content">
        <div>
          <div
            class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
          >
            <div class="sb-contentColumn__inner">
              <div class="md-flex">
                <div class="adpageimage flex justify-center items-center "
                  style="background-color: #f4c5a1"
                >
                  <img
                    alt="目标树帮你梳理你的思路，组织你的项目与任务清单"
                    class="block goaltreeimg sizeimg userimg"
                    src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/newgoaltree.png"
                  />
                </div>
                <div
                  class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                  style="background-color: #f4c5a1"
                >
                  <div class="oddcontent imgcontent">
                    <h2 class="infogrouptitle">
                      <span class="infotitle"
                        >{{ $t('goaltreetext') }}</span
                      >
                    </h2>
                    <div class="">
                      <ul class="blackfont">
                        <li>{{ $t('goaltreefun1') }}</li>
                        <li>{{ $t('goaltreefun2') }}</li>
                      </ul>
                    </div>
                    <a class="userbutton" @click="showusersystemfunc"
                      >{{ $t('try') }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
            id="137-82986"
          >
            <div class="sb-contentColumn__inner">
              <div class="md-flex row-reverse">
                <div
                  class="adpageimage flex justify-center items-center "
                  style="background-color: #d4e9e2"
                >
                  <img
                  alt="任务树帮助你不断拆解你的复杂任务，克服情绪上的障碍"
                    class="block imagetasktree sizeimg userimg"
                    src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/tasktree.png"
                  />
                </div>
                <div
                  class="py5 flex items-center flex justify-center md-size6of12 textBlockGutter"
                  style="background-color: #d4e9e2"
                >
                  <div class="evencontent imgcontent">
                    <h1
                      class="infogrouptitle mb4 md text-semibold"
                      tabindex="-1"
                      style="color: #1e3932"
                    >
                      <span class="infotitle"
                        >{{ $t('tasktreetext') }}</span
                      >
                    </h1>
                    <div style="color: #1e3932">
                      <ul class="blackfont">
                        <li>{{ $t('tasktreefun1') }}</li>
                        <li>{{ $t('tasktreefun2') }}</li>
                        <li>{{ $t('tasktreefun3') }}</li>
                        <li>{{ $t('tasktreefun4') }}</li>
                      </ul>
                    </div>
                    <a class="userbutton" @click="showusersystemfunc"
                      >{{ $t('try') }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
            id="137-82561"
          >
            <div class="sb-contentColumn__inner">
              <div class="md-flex image-content-block_flexRowReverse__AEmTi">
                <div
                  class="adpageimage   flex justify-center items-center "
                  style="background-color: rgb(210 210 241);"
                >
                  <img
                  alt="评价算法帮助你获得实时反馈，获得奇妙的心流体验"
                    class="block smallimg sizeimg userimg"
                    src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/reviewalgo.png"
                  />
                </div>
                <div
                  class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                  style="background-color: rgb(210 210 241)"
                >
                  <div class="imgcontent">
                    <h2
                      class="infogrouptitle mb4 xl text-semibold"
                      tabindex="-1"
                      style="color: #ffffff"
                    >
                      <span
                        class="rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d infotitle"
                        >{{ $t('evasystext') }}</span
                      >
                    </h2>
                    <div
                      class="md rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d blackfont"
                    >
                    {{ $t('evasysfunc1') }}
                      <ul>
                        <li>  {{ $t('evasysfunc2') }}</li>
                        <li> {{ $t('evasysfunc3') }}</li>
                        <li> {{ $t('evasysfunc4') }}</li>
                      </ul>
                    </div>

                    <a
                      class="userbutton"
                      style="min-width: 35px; min-height: 35px"
                      @click="showusersystemfunc"
                      >{{ $t('try') }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            class="sb-contentColumn sb-global-gutters mx-auto sb-contentColumn--xlarge px0 my5"
            id="137-82563"
          >
            <div class="sb-contentColumn__inner">
              <div
                class="md-flex row-reverse image-content-block_flexRowReverse__AEmTi"
              >
                <div
                  class="adpageimage flex justify-center items-center "
                  style="background-color: #a3bc97"
                >
                  <img
                  alt="我们关注你的隐私，绝不泄露"
                    class="block smallimg sizeimg userimg"
                    src="https://images-1255367272.cos.ap-chengdu.myqcloud.com/privacy.jpg"
                  />
                </div>
                <div
                  class="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
                  style="background-color: #a3bc97"
                >
                  <div class="imgcontent">
                    <h2
                      class="infogrouptitle mb4 xl text-semibold"
                      tabindex="-1"
                      style="color: #ffffff"
                    >
                      <span
                        class="rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d infotitle"
                        > {{ $t('privacytext') }}</span
                      >
                    </h2>
                    <div
                      class="md rich-text_text__02jRf rich-text_width__0GHdb rich-text_greenLinks__BNG4d blackfont"
                    >
                      <ul>
                        <li>{{ $t('privacyfunc1') }}</li>
                        <li>{{ $t('privacyfunc2') }}</li>
                        <li>{{ $t('privacyfunc3') }}</li>
                      </ul>
                    </div>
                    <a class="userbutton" @click="showusersystemfunc"
                      >{{ $t('try') }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<div class="adzone">
 <h3 class="font-bold text-center " data-aos="fade-up">
atomTask已经帮助用户解决
<br>
<a class="adnum">40,000+</a> 的日常任务，共在任务上投入<a class="adnum1">23360+</a> 小时
</h3>

<p    class="introtext " data-aos="fade-up">
  {{ $t('declaim') }}
</p>
       
</div>
      </main> -->
      <productzone v-on:setTime="showusersystemfunc"  />
      <div class="footerzone">
        <footer class="py4 relative footer_footer__ruM_4">
          <nav
            aria-label="Global footer"
            class="thisnav sb-global-gutters--maxWidthLayout"
          >
            <div class="lg-flex">
              <div class="infogroup">
                <h2 class="infogrouptitle text-sm mb4" tabindex="-1">
                  {{ $t('about') }}
                </h2>
                <ul class="infolist">
                  <li class="">
                    <a class="" 
                      > {{ $t('about1') }}</a
                    >
                  </li>

                  <li class="">
                    <a
                      class="
                      "
                    
                      >{{ $t('about2') }}</a
                    >
                  </li>

                  <!-- <li class="">
                    <a
                      class="block mb2 py2 text-noUnderline sb-footer-primaryLinks null-pr0"
                     
                      >Customer Service</a
                    >
                  </li> -->
                </ul>
              </div>


              <div class="infogroup">
                <h2 class="infogrouptitle text-sm mb4" tabindex="-1">
                  产品
                </h2>
                <ul class="infolist">
                  <li class="">
                    <a 
                      > atomTask</a
                    >
                  </li>

                  <li class="">
                    <a
                      class="
                      "
                    href="/software"
                      >rongbrain</a
                    >
                  </li>
                  <li class="">
                    <a 
                      >ThinkingforLife</a
                    >
                  </li>
                  <!-- <li class="">
                    <a
                      class="block mb2 py2 text-noUnderline sb-footer-primaryLinks null-pr0"
                     
                      >Customer Service</a
                    >
                  </li> -->
                </ul>
              </div>

              <div class="sb-footer-columnWidth hidden lg-inline-block lg-ml5">
                <h2 class="infogrouptitle text-sm mb4" tabindex="-1">
                  {{ $t('career') }}
                </h2>
                <ul class="infolist">
                  <li class="">
                    <a
                      class="block mb2 py2 text-noUnderline sb-footer-primaryLinks null-pr0"
                   
                      >  {{ $t('career1') }}</a
                    >
                  </li>
                 <li class="">
                    <a
                      class="block mb2 py2 text-noUnderline sb-footer-primaryLinks null-pr0"
                      >{{ $t('career2') }}</a
                    >
                  </li>
                    <!--
                  <li class="">
                    <a
                      class="block mb2 py2 text-noUnderline sb-footer-primaryLinks null-pr0"
                    
                      >use in other country outside china</a
                    >
                  </li> -->
                </ul>
              </div>

         
            </div>
            <hr aria-hidden="true" class="sb-rule py5" />
                   <ul class="inline-block mt3 lg-mb1 lg-flex">
              <!-- <li class="items-center lg-flex lg-text-center">
                <a
                  class="block sb-footer-secondaryLinks mb1 py2 lg-mb0 lg-py0 lg-pr3"
                
                  >Privacy Notice</a
                >
              </li> -->
              <li class="items-center lg-flex lg-text-center">
                <span
                  aria-hidden="true"
                  class="color-textBlackSoft px2 hidden lg-inline"
                  >|</span
                ><a
                  class="block sb-footer-secondaryLinks mb1 py2 lg-mb0 lg-py0 lg-px3"
               
                  >{{ $t('termuse') }}</a
                >
              </li>

         
            </ul>
            <div class="infoofslogan">
            <h1 class="sci-fi-text">
            atomTask <span class="animated-text">Helps You</span>
            Take acti<img  ref="rocketIcon" src="../assets/rocketpro.gif" 
            alt="Rocket GIF" class="rocket-icon"
            :style="{ animationDuration: animationDuration }"
            @mouseenter="speedUp" @mouseleave="restoreSpeed">n
            
          </h1>
        
        </div>
            <p class="cinfo">
              ©2024 寰宇构象成都软件有限公司. 蜀ICP备2023018201号
              
            </p>
        
          </nav>
        </footer>
       
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import * from "../configure/index.js"
import configure_options from "../configure";
import agreezone from "./softwareprotocal.vue"
import { useStore } from 'vuex';
import productzone from './product.vue'
import { defineComponent, ref } from "vue";
import { notification } from 'ant-design-vue';
// axios.defaults.withCredentials = true
export default defineComponent({
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components:{
    // Taskszone,
    agreezone,
    productzone,
  },
  data() {},
  setup(props, context) {
    console.log(props, context);
    const store = useStore();
    var isLoggingIn = ref(false);

   function tologin() {
      console.log("服务器配置读取");
      console.log(configure_options);
      var env = configure_options["env"];

      console.log(configure_options["app_setting"][env]);
      var serverhost = configure_options["app_setting"][env]["serverhost"];

      var userinfo = {
        email: this.loginemail,
        password: this.loginpassword,
        clent: "web",
      };
      if (this.loginemail == "" || this.loginpassword == "") {
        alert("用户名与密码不能为空");
        return;
      }
      this.isLoggingIn = true
  
      axios
        .post(serverhost + "/v1/login", userinfo)
        .then((response) => {
      
        store.dispatch('fetchUser');
  
                notification.success({
        message: '登录成功',
        description: '开始你的探险之旅❤️',
        style: {
          fontFamily: 'KaiTi, sans-serif',
          fontSize: '16px',
          color: '#333', // 字体颜色
        },
      });
          console.log(response);
          if (response.data.mode == "travel") {
            this.$router.push("/travel");
          } else {
            this.$router.push("/taskmanager");
          }
        })
        .catch((error) => {
          if (error.response.status == 423) {
            console.log(error);
            alert("请联系管理员激活");
          } else {
           
            notification.error({
  message: '错误通知',
  description: '用户名或密码错误。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
          }
        })
        .finally(() => {
        this.isLoggingIn = false; // 请求完成后恢复按钮状态
      });
        
        
        
    }
    return {
      emailcodeword:ref("点击获取邮箱验证码"),
      resetemailcodeword:ref("点击获取邮箱验证码"),
      buttondisable:ref(false),
      resetbuttondisable:ref(false),
      tologin,
     isLoggingIn,

      showimage:ref(true),
      showagreement:ref(false),
      checked:ref(false),
      showusersystem: ref(false),
      loginemail: ref(""),
      loginpassword: ref(""),
      registername: ref(""),
      registeremail: ref(""),
      registerpassword: ref(""),
      repeatpassword: ref(""),
      verifycode:ref(""),
      resetemail: ref(""),
      resetpassword: ref(""),
      resetverifycode:ref(""),
      repeatresetpassword: ref(""),
      showlogin: ref(true),
      showregister:ref(true),
      showreset:ref(true),
      

      rocketId: ref('rocket-icon'), // 图片元素的 ID
      rocketSrc: '../assets/rocketpro.gif', // 图片的路径
      initialSpeed: '10s', // 初始播放速度
      hoverSpeed: '0.1s' ,// 悬停时的播放速度
      animationDuration: ref('10s') // 使用响应式引用对象
    };
  },
  methods: {


  



    speedUp() {
      this.animationDuration = this.hoverSpeed; // 修改动画速度
    },
    restoreSpeed() {
      this.animationDuration = this.initialSpeed; // 恢复初始动画速度
    },

    getemailverifycode(){
      var env = configure_options["env"];
      var serverhost = configure_options["app_setting"][env]["serverhost"];
      // alert(this.registername)
      if (this.registeremail == "" ||this.registername=="") {
        alert("用户名,邮件,验证码与密码不能为空");
        return;
      }
      console.log(configure_options["app_setting"][env]);
    
      var userinfo = {
        username: this.registername,
        email: this.registeremail,
        client: "clientv2web",
      };
   



      axios
        .post(serverhost + "/v1/emailverify", userinfo)
        .then((response) => {
          alert("邮件已发送,请填写验证码");
          console.log(response);
          this.startCountdown()
          // this.$router.push("/taskmanager")
        })
        .catch((e) => {
          //这里可以填入各种邮件认证出现的问题
          alert("获取验证码异常，请联系管理员")
         e.preventDefault();
        });
      




       
    },
    startCountdown() {

  
  var countdownSeconds = 120; // 设置倒计时秒数
  const countdownInterval = setInterval(() => {
    countdownSeconds--;
    if (countdownSeconds <= 0) {
      clearInterval(countdownInterval);
      this.emailcodeword = `获取验证码`;
      this.buttondisable = false
      
    } else {
      this.emailcodeword = `倒计时 ${countdownSeconds} 秒`;
      this.buttondisable = true;

    }
  }, 1000);
},




startresetCountdown() {

  
var countdownSeconds = 120; // 设置倒计时秒数
const countdownInterval = setInterval(() => {
  countdownSeconds--;
  if (countdownSeconds <= 0) {
    clearInterval(countdownInterval);
    this.resetemailcodeword = `获取验证码`;
    this.resetbuttondisable = false
    
  } else {
    this.resetemailcodeword = `倒计时 ${countdownSeconds} 秒`;
    this.resetbuttondisable = true;

  }
}, 1000);
},



    getResetemailrverifycode(){

  // alert("点击了重设密码部分")

   var email = this.resetemail
   var verifycode = ""
   var env = configure_options["env"];
  var serverhost = configure_options["app_setting"][env]["serverhost"];
  //  var confirmpassword = login_info[2].value
  // console.log("点击了获取验证码部分")
  console.log(email);
   console.log(verifycode);
   if(email==""){
   alert("请补全邮件地址哟")
 
   return 0;
   }
   var username = "empty"
   var account_info = {
      'email':email,
      'username':username,
      'client':'web'
   }
   console.log(account_info)
  //注意这里使用的了nginx的反向代理
   var verifyurl
   verifyurl = serverhost + `/v1/emailverify`

   axios.post(verifyurl,account_info )
   .then(response => {


    this.startresetCountdown() 
     var serverdata = response.data
    //  print("response","blue")
     console.log(response)
    //  print("reponse头部","green")
     console.log(response.headers)
    //  print("reponse头部-setcookie","green")
     console.log(response.headers['set-cookie'])
    //  print("打印用户系统返回的东西","blue")
     console.log(serverdata)

     alert("邮件验证码发送成功")
 
   })
   .catch(e => {
   
    if (e.response.status==455){
     var data=  e.response.data

    var error_string =""
    
    for (const key in data) {
      // console.log(`${key}: ${reviewalgo_covert[key]}`);data.push(reviewalgo_covert[key])
  
     error_string += key+":"+ data[key]+" "
    }
    alert(error_string)
    }else if(e.response.status==422){
      alert("服务异常请稍后再试")
    
    }else{
      alert("账户异常，请联系管理员")
    }

   })  
}
,



    showresetfunc(){
    this.showlogin = false;
    this.showregister = false;
    this.showreset = true;
    },
    showusersystemfunc() {
      this.showusersystem = true;
      // document.getElementById("userpage").style.backgroundColor = "#d9e9d1";
    },

    showfeaturesfunc() {
      this.showusersystem = false;
      document.getElementById("userpage").style.backgroundColor = 'white';
    },



    showagreezone(){

      this.showimage=false;
      this.showagreement =true;
    },

    showimagezone(){

this.showimage=true;
this.showagreement =false;
},


    godoc() {
      this.$router.push("/docweb");
    },

    toregister() {
      console.log("服务器配置读取");
      console.log(configure_options);

      console.log("服务器配置读取");
      console.log(configure_options);
      var env = configure_options["env"];
      // alert(this.registername)
      if (this.registeremail == "" || this.registerpassword == ""||this.registername==""||this.verifycode=="") {
        // alert("用户名,邮件与密码不能为空");
        notification.error({
  message: '错误通知',
  description: '用户名,邮件与密码不能为空。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
        return;
      }

      console.log(configure_options["app_setting"][env]);
      var serverhost = configure_options["app_setting"][env]["serverhost"];

      var userinfo = {
        username: this.registername,
        email: this.registeremail,
        password: this.registerpassword,
        verifycode:this.verifycode,
        client: "clientv2",
            
      };

      if (this.repeatpassword != this.registerpassword) {
        notification.error({
  message: '错误通知',
  description: '两次输入密码不一致。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
        
        return;
      }

      if(this.checked==false){
        
        notification.error({
  message: '错误通知',
  description: '请同意软件使用协议。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
      }

      console.log(userinfo);
      console.log(serverhost);
      axios
        .post(serverhost + "/v1/register", userinfo)
        .then((response) => {
       
          notification.success({
  message: '成功通知',
  description: '注册成功,可以登录。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: '#333', // 字体颜色
  },
});
          console.log(response);
          // this.$router.push("/taskmanager")
        })
        .catch((e) => {
          if (e.response.status == 409) {
            var data = e.response.data;

            var error_string = "";

            for (const key in data) {
              // console.log(`${key}: ${reviewalgo_covert[key]}`);data.push(reviewalgo_covert[key])

              error_string += key + ":" + data[key] + " ";
            }
            alert(error_string);
          } else if(e.response.status == 438){
            // alert("验证码错误，请重新输入");
            notification.error({
  message: '错误通知',
  description: '验证码错误，请重新输入。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
          }
          
          
          
          else if (e.response.status == 422) {
            // alert("创建账户异常，请联系管理员");
            notification.error({
  message: '错误通知',
  description: '创建账户异常，请联系管理员',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
          } else {
            // alert("创建账户异常，请联系管理员");
            notification.error({
  message: '错误通知',
  description: '创建账户异常，请联系管理员。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
          }

          e.preventDefault();
        });
    },
    toreset() {
      console.log("服务器配置读取");
      console.log(configure_options);

      console.log("服务器配置读取");
      console.log(configure_options);
      var env = configure_options["env"];

      if (this.resetemail == "" || this.resetpassword == ""||this.resetverifycode=="") {
        alert("邮件,密码,验证码不能为空");
        return;
      }

      console.log(configure_options["app_setting"][env]);
      var serverhost = configure_options["app_setting"][env]["serverhost"];

      var userinfo = {
      
        email: this.resetemail,
        password: this.resetpassword,
        verifycode:this.resetverifycode,
        clent: "clientv2",
      };

      if (this.repeatresetpassword != this.resetpassword) {
       
        notification.error({
  message: '错误通知',
  description: '两次输入密码不一致。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
        return;
      }

    
      console.log(userinfo);
      console.log(serverhost);
      axios
        .post(serverhost + "/v1/resetpass", userinfo)
        .then((response) => {
          notification.success({
  message: '成功通知',
  description: '密码修改成功。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: '#333', // 字体颜色
  },
});
          console.log(response);
          // this.$router.push("/taskmanager")
        })
        .catch((e) => {
          if (e.response.status == 409) {
            var data = e.response.data;

            var error_string = "";

            for (const key in data) {
              // console.log(`${key}: ${reviewalgo_covert[key]}`);data.push(reviewalgo_covert[key])

              error_string += key + ":" + data[key] + " ";
            }
            alert(error_string);
          } else if (e.response.status == 438) {
            // alert("邮件验证码不正确");
            notification.error({
  message: '错误通知',
  description: '右键验证码不正确。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
          } else {
            // alert("修改异常，请联系管理员");
            notification.error({
  message: '错误通知',
  description: '修改异常，请联系管理员。',
  style: {
    fontFamily: 'KaiTi, sans-serif',
    fontSize: '16px',
    color: 'red', // 字体颜色
  },
});
          }

          e.preventDefault();
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* Form */
/* 顶层按钮区域样式 */
.topbuttonzone {
  display: flex; /* 横向排列 */
  gap: 10px; /* 按钮间距 */
  justify-content: center; /* 居中对齐 */
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 区域阴影效果 */
}

/* 按钮样式 */
.pushable {
  background: hsl(210deg 100% 38%); /* 按钮背景色 */
  border-radius: 50%;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* 动画效果 */
}

/* 内层按钮内容 */
.pushable .front {
  display: block;
  padding: 20px 20px;
  border-radius: 50%;
  font-size: 0.8rem;
  background: hsl(210deg 100% 50%);
  color: white;
  text-align: center;
  transform: translateY(-6px); /* 3D 效果 */
  transition: transform 0.2s ease; /* 悬停动画 */
  text-decoration: none; /* 去掉链接下划线 */
  font-weight: bold; /* 加粗文本 */
}

/* 按钮悬停效果 */
.pushable:hover .front {
  transform: translateY(-2px); /* 向上浮动 */
}

/* 点击时的按下效果 */
.pushable:active .front {
  transform: translateY(0); /* 恢复到初始状态 */
}

/* 特定链接颜色 */
.front.logincaption {
  background: hsl(340deg 100% 47%);
}



.infoofslogan {
  display: flex;
  justify-content: center;
  align-items: center;

}

.sci-fi-text {
  font-family: 'scitext', sans-serif; 
  color: #00ff00; /* 设置绿色文本 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 添加文字阴影 */
  display: flex;
  align-items: center;
  word-spacing: 8px; 
  font-size: 20px;
}

.animated-text {
  display: inline-block;
  margin:0px;
  animation: glow 2s infinite alternate; /* 添加一个闪烁的动画效果 */
//   letter-spacing: 2px; 

}

@keyframes glow {
  from {
    color: #00ff00;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  to {
    color: #ff00ff; 
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.5); 
  }
}

.rocket-icon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  
  animation: rotate infinite; 
}



@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 使用动画 */




:root {
  --font-family-sans: "Graphik Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --font-family-heading: "Graphik Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --font-family-body: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.adzone{

  display: flex;
  flex-direction: column;
  justify-content: center;


  .font-bold {
    font-weight: 700;
    font-size: 40px;
}
.introtext{
 

    color: rgb(54 61 78);
    // text-align: center;
    // max-width: 30vw;
}

}


body {
  height: 100%;
  width: 100%;
}

.resetverifyzone{
  display: flex;
  flex-direction: row;
}
.cinfo{
  font-size:12px;
}
#top{
  margin-bottom: 5px;
}
nav {
  /* position:absolute; */
  top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-right: 8%;
}
nav ul li {
  list-style: none;
  display: inline-block;
  padding: 10px 25px;
}
nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  text-transform: capitalize;
}

nav ul li a:hover {
  color: red;
  transition: 0.4s;
}

span {
  color: red;
}


.topsci{
  margin-left:0.5vw;
  margin-top:1.5vh;
}


.logotitle{
  display: flex;
  flex-direction: row;
  align-items: center;

h1.logo {
  margin-left: 40px;
  margin-bottom: 0px;
  /* left:20%; */
  /* float:left; */
  font-size: 40px;
  line-height: 40px;
}

.logoinfo{
  border-top: solid 1px blue;
  color:black;
  font-weight: 600;
}

}

#userpage {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  overflow-y:auto;
  #middlezone {
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
    overflow-y: auto;
    #usersystem {
      position: absolute;
      // position:relative;
      top: 10%;
      right: 10%;
      bottom:0%;
      align-items: flex-end;
      // max-height: 400px;
      /* bottom:10%; */
      
    .form {
  
  // z-index: 1;
  /* 最后一个参数为透明的颜色 */
    background: rgba(3, 3, 3, 0.1);
    max-width: 300px;
    margin: 0 auto 100px; 
    padding: 10px;
   
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;
    
    input {
      margin-left: 10px;
    margin-right:10px;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 5px;
    border-radius: 70px;
    /* border-top-left-radius: 3px; */
    /* border-top-right-radius: 3px; */
    /* border-bottom-left-radius: 3px; */
    /* border-bottom-right-radius: 3px; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
  }
  .thumbnail {
    background: #8acecb;
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    padding: 50px 30px;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    img {
      display: block;
      width: 100%;
    }
  }

  button {
    outline: 0;
    background: red;
    width: 100%;
    border: 0;
    padding: 8px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #ffffff;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  .message {
    margin: 15px 0 0;
    /* color:#f01515; */
    color: #ac3434;
    font-size: 12px;
    a {
      color: #0c0c0b;
      text-decoration: none;
    }
    a:hover{
      color:red;
    }
  }

  .register-form {
    display: none;
  }


}
    }

    $top: 20%;
    #showimage {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      position: absolute;
      top: $top;
      left: 10%;
    }


    .agreezone {
 display: flex;
 flex-direction: column;
 position:fixed;
 align-items:flex-end;
 background: #8acecb;
 width:40vw;
 height:50vh;
 overflow-y: auto;
 left:10vw;
 top:20vh;

 #closeagree{
  max-width:60px;

 }
}
    .imageforlogin {
      max-width: 30%;
      height: auto;
    }
  }

  .bottomzone {
    position: absolute;
    left: 50%;
    color: black;
    bottom: 1%;
  }
}

/* Form */

.container {
  // position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
  .info {
    margin: 10px auto;
    text-align: center;
  }
  .info h1 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 26px;
    font-weight: 300px;
    font-family: fangsong;
    color: #1a1a1a;
  }
  .info span {
    color: #4d4d4d;
    font-size: 10px;
  }
  .info span a {
    color: #000000;
    text-decoration: none;
  }
  .info span .fa {
    color: #ef3b3a;
  }
}

/* END Form */
/* Demo Purposes */

.logincaption {
  padding: 5px;
  // margin-left: 10px;
  // margin-right: 10px;
  color: #fff;
  border-radius: 5px;
  background: #3e12db;
}

.imgcontent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  
}




.md-flex {
  display: flex !important;
}

.textBlockGutter {
  padding: 0 var(--space-7);
}
.py5 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important;
}

.md-size6of12 {
  width: calc(100% * (6 / 12));
  // max-width:50vw
}

.justify-center {
  justify-content: center !important;
}

.items-center {
  align-items: center !important;
}

.text-center {
  text-align: center !important;
}
.block {
  display: block !important;
  font-family: var(--font-family, var(--font-family-body));
  --font-size: clamp(
    var(--min-font-size) * 1rem,
    var(--intersection) * 1rem + var(--slope) * 100vw,
    var(--max-font-size) * 1rem
  );
}

img {
  max-width: 100%;
  height: auto;
}
img {
  border: 0;
}
.flex {
  display: flex !important;
}

.rich-text_width__0GHdb {
  max-width: calc(1em * (calc(600 / 13)));
}

.sm {
  font-size: 19px;
}

.infotitle {
  display: block;
  font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-wrap: break-word;
  color: #000000;
  text-align: left;
  font-size: 28px;
}

.footer_footer__ruM_4 {
  box-shadow: 0 -1px 3px red, 0 -2px 2px red, 0 0 2px black;
}
.relative {
  position: relative !important;
}
.py4 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important;
}
.blackfont {
  color: black;
}

.sb-global-gutters {
  padding-left: 4rem;
  padding-right: 4rem;
}

.sb-global-gutters {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.sb-global-gutters {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.lg-flex {
  display: flex !important;
  justify-content: space-evenly;
}

.sb-rule {
  border: 0;
  margin: 0;
}
.py5 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important;
}

.sb-footer-iconMarginAdjust {
  margin-left: -5px;
}

.sizeimg{
    float: left;
    width:  60vw;
    // height: 60vh;
    object-fit: cover;
}

.adnum{
  color:red
}

.adnum1{
  color:blue;
}

.emailverifyzone{
  display:flex;
  flex-direction: row;



  .verifybutton{
    margin-top:5px;
  color:#4772fa;
  height: 40px;
  width:80px;
  font-size: small;

  
  text-align: center;
  }
}


.footerzone {
  max-width: 100vw;
  overflow: hidden;
  .infolist {
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  .infogrouptitle {
    text-align: center;
    font-size: 20px;
  }
}

.social_media {
  position: relative;
  left: 40%;
}

.flex {
  display: flex !important;
}
/* 什么 */
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.thisnav {
  display: block;
}

.text-xxs {
  font-size: 1.4rem !important;
}
.text-xxs {
  font-size: 1.3rem !important;
}
.py3 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important;
}
.color-textBlackSoft {
  color: rgba(0, 0, 0, 0.58) !important;
}

.lg-inline-block {
  display: inline-block !important;
}

.lg-ml5,
.lg-mx5 {
  margin-left: 3.2rem !important;
}

.evencontent {
  margin-left: 20px;
}

.infogroup {
  display: flex;
  flex-direction: column;

  .infogroupdetail {
    display: flex;
    flex-direction: column;
  }
}
.row-reverse {
  flex-direction: row-reverse;
}



imagebox{
  object-fit: fill;
}

.adpageimage{
  min-width: 50vw;
  max-width:50vw;
 
  height: auto;
 

  .goaltreeimg{
  padding: 10%;
  // border-radius: 80px;
}
  
.imagetasktree{
  padding:10%;
  // border-radius: 300px;
}


.smallimg {
  padding: 10%;
  // border-radius: 300px;
}
}




.topnav{
  padding-right: 5%;
}

.userbutton {
  border-radius: 10px;
  position: relative;
  top: 10px;
  background: rgb(60, 58, 230);

  padding: 4px;
  color: white;
  font-weight: 700;
  &:hover {
    background: #aa9595;
  }
}
</style>