<template>

<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="icomoon-ignore">
</g>
<path d="M27.995 5.338v-1.654c0-1.442-1.169-2.611-2.611-2.611h-17.167c-1.442 0-2.61 1.169-2.61 2.611v1.654h-0.489c-1.204 0-2.178 0.976-2.178 2.179v3.649c0 1.203 0.975 2.179 2.178 2.179l10.037-0.003c0.613 0 1.113 0.499 1.113 1.113v3.679h-2.131v12.795h5.331v-12.795h-2.134v-3.679c0-1.203-0.976-2.179-2.179-2.179h-1.32v0.003h-8.716c-0.613 0-1.112-0.499-1.112-1.113v-3.649c0-0.613 0.499-1.113 1.112-1.113h0.489v1.654c0 1.442 1.168 2.611 2.61 2.611h17.167c1.442 0 2.611-1.169 2.611-2.611v-1.654h1.066v-1.066h-1.066zM18.4 29.861h-3.199v-10.663h3.199v10.663zM26.929 8.058c0 0.852-0.694 1.545-1.545 1.545h-17.167c-0.852 0-1.544-0.693-1.544-1.545v-4.374c0-0.852 0.692-1.545 1.544-1.545h17.167c0.852 0 1.545 0.693 1.545 1.545v4.374z" fill="#000000">

</path>
</svg>
</template>
<script>
export default {
    name: "rocketicon",
};
</script>

<style scoped></style>