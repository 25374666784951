export default {
    mounted(el) {
      el.style.position = 'absolute';
      el.style.cursor = 'grab';
      el.style.userSelect = 'none';
      el.style.zIndex = 10;
  
      let shiftX, shiftY;
  
      const onMouseMove = (event) => {
        let newX = event.clientX - shiftX;
        let newY = event.clientY - shiftY;
  
        // 限制元素在窗口范围内，不让它拖出窗口
        newX = Math.max(0, Math.min(newX, window.innerWidth - el.offsetWidth));
        newY = Math.max(0, Math.min(newY, window.innerHeight - el.offsetHeight));
  
        el.style.left = `${newX}px`;
        el.style.top = `${newY}px`;
      };
  
      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
  
      const onMouseDown = (e) => {
        if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
          e.preventDefault();  // 仅当目标不是输入框时才阻止默认行为
        }
  
        // 获取鼠标点击时相对于元素左上角的位置
        shiftX = e.clientX - el.getBoundingClientRect().left;
        shiftY = e.clientY - el.getBoundingClientRect().top;
  
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      };
  
      el.addEventListener('mousedown', onMouseDown);
  
      el.ondragstart = function () {
        return false;
      };
    }
  };
  