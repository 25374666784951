<template>
     <div
    :style="{
      fontSize: `${size}px`,
      color: color,
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: '1',
    }"
  >
    <!-- 根据 type 动态渲染不同的 Emoji -->
    <span v-if="type === 'hard'">🔥</span>
    <span v-else-if="type === 'lowmood'">😞</span>
    <span v-else-if="type === 'novelty'">✨</span>
    <span v-else-if="type === 'mutiplegoal'">🚩</span>
    <span v-else-if="type === 'hardrelated'">💪</span>
    <span v-else-if="type === 'lowmoodrelated'">💢</span>
    <span v-else>🟢</span> <!-- 默认图标 -->
  </div>
  </template>
  
  <script>
  export default {
    name: "DynamicIcon",
    props: {
      type: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: true,
      },
      size: {
        type: Number,
        default: 13,
      },
    },
  };
  </script>
  