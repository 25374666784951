<template>
    <div class="wrapper">
      <div class="header">
        <span class="title">精力分配图</span>
        <a-segmented v-model:value="selectedOption" :options="segmentOptions" />
      </div>
      <div ref="treeMapContainer" class="tree-map-container"></div>
    </div>
  </template>
  
  <script>
  import * as d3 from "d3";
  import { onMounted, ref, nextTick, watch } from "vue";
  import axios from "axios";
  import { Segmented } from "ant-design-vue";
  
  export default {
    name: "NestedTreeMap",
    components: {
      "a-segmented": Segmented,
    },
    setup() {
      const treeMapContainer = ref(null);
      const selectedOption = ref("month"); // 默认选项为按月
      const segmentOptions = [
        { label: "本周", value: "week" },
        { label: "本月", value: "month" },
        { label: "上周", value: "lastweek" },
      ];
  
      const customDarkColors = [
        "#003f5c", "#374c80", "#7a5195", "#bc5090", "#ef5675", "#ff764a",
        "#ffa600", "#4e4d4a", "#2f4b7c", "#665191", "#d45087", "#f95d6a",
      ];
  
      const fetchData = async (timePeriod) => {
        try {
          const response = await axios.get("/v1/summarytreemap", {
            params: { timePeriod },
          });
          if (response.data && response.data.data) {
            return response.data.data;
          } else {
            console.error("Invalid API response format");
            return null;
          }
        } catch (error) {
          console.error("Failed to fetch Treemap data:", error);
          return null;
        }
      };
  
      const createTreeMap = async (timePeriod) => {
        const data = await fetchData(timePeriod);
        if (!data) {
          console.error("No data to render Treemap");
          return;
        }
  
        const container = treeMapContainer.value;
        if (!container || container.offsetWidth === 0 || container.offsetHeight === 0) {
          console.error(
            "Container size is zero. Ensure the container is visible and has a defined width and height."
          );
          return;
        }
  
        // 清空容器，避免重复渲染
        d3.select(container).selectAll("*").remove();
  
        const width = container.offsetWidth;
        const height = container.offsetHeight;
  
        const svg = d3
          .select(container)
          .append("svg")
          .attr("width", width)
          .attr("height", height)
          .style("font-family", "Arial, sans-serif");
  
        const root = d3
          .hierarchy(data)
          .sum((d) => d.value || 0)
          .sort((a, b) => b.value - a.value);
  
        const treemap = d3
          .treemap()
          .size([width, height])
          .round(true)
          .paddingInner(2)
          .paddingOuter(4)
          .paddingTop((d) => (d.depth === 1 ? 20 : 0));
  
        treemap(root);
  
        const colorScale = d3.scaleOrdinal(customDarkColors);
  
        const nodes = svg
          .selectAll("g")
          .data(root.descendants())
          .enter()
          .append("g")
          .attr("transform", (d) => `translate(${d.x0},${d.y0})`);
  
        // Draw rectangles
        nodes
          .append("rect")
          .attr("width", (d) => d.x1 - d.x0)
          .attr("height", (d) => d.y1 - d.y0)
          .attr("fill", (d) =>
            d.children
              ? colorScale(d.data.name) // Internal nodes get distinct colors
              : colorScale(d.parent.data.name) // Leaves inherit their parent's color
          )
          .attr("stroke", "#fff");
  
        // Label internal nodes
        nodes
          .filter((d) => d.children) // Only internal nodes
          .append("text")
          .attr("x", 4)
          .attr("y", 14)
          .text((d) => d.data.name)
          .attr("fill", "white")
          .attr("font-size", "14px")
          .attr("font-weight", "bold")
          .attr("clip-path", "inherit"); // Prevents text overflow
  
        // Label leaf nodes with truncated text
        nodes
          .filter((d) => !d.children) // Only leaf nodes
          .append("text")
          .attr("x", 4)
          .attr("y", 14)
          .text((d) => {
            const blockWidth = d.x1 - d.x0;
            const maxChars = Math.floor(blockWidth / 7); // Estimate max characters based on width
            return d.data.name.length > maxChars
              ? d.data.name.slice(0, maxChars - 2) + "..." // Truncate if necessary
              : d.data.name;
          })
          .attr("fill", "white")
          .attr("font-size", "10px");
  
        // Add tooltips to show full text for both internal and leaf nodes
        nodes
          .append("title")
          .text((d) => d.data.name);
      };
  
      onMounted(() => {
        nextTick(() => {
          createTreeMap(selectedOption.value);
        });
      });
  
      watch(selectedOption, (newOption) => {
        createTreeMap(newOption);
      });
  
      return {
        treeMapContainer,
        selectedOption,
        segmentOptions,
      };
    },
  };
  </script>
  
  <style scoped>
  .wrapper {
    background-color: white;
  }
  
  .tree-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 50vh;
    min-height: 600px;
    overflow: hidden;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title {
    font-family: "goodfont";
    font-size: 16px;
    font-weight: bold;
  }
  </style>
  